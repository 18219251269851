import React from 'react'
import styled from 'styled-components'
import Button from './Button'

const Wrapper = styled.section`
  position: relative;
  /* // margin-top: 90px; */
  min-height: 300px;
  margin-bottom: 1em;
  padding: 109px 0 0;
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidthCentered};

  @media screen and (max-width: 1100px) {
    padding: 0 1.5em;
  }
`

const Title = styled.h1`
  width: 100%;

  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 50px;
  /* or 156% */
  letter-spacing: 0.18em;

  color: ${props => props.theme.colors.tertiary};
  margin-bottom: 20px;

  @media screen and (max-width: 1440px) {
    font-size: 32px;
  }

  @media screen and (max-width: 1000px) {
    letter-spacing: 4px;
    font-size: 28px;
  }

  @media screen and (max-width: 820px) {
    letter-spacing: 3px;
    margin-bottom: 25px;
    font-size: 20px;
  }

  @media screen and (max-width: 670px) {
    font-size: 15px;
  }

  @media screen and (max-width: 600px) {
    letter-spacing: 3px;
    margin-bottom: 15px;
    font-size: 12px;
  }
`

const Text = styled.div`
  margin-top: 45px;
  margin-bottom: 35px;
  width: 100%;

  /* display: flex; */
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 45px;
  /* or 205% */
  letter-spacing: 0.18em;

  color: ${props => props.theme.colors.secondary};
  margin-bottom: 1em;

  & strong {
    font-weight: bold;
  }

  & span {
    font-size: 20px;
    color: ${props => props.theme.colors.tertiary};
    text-align: center;
    display: contents;
    display: inline-block;
    line-height: 0;
    vertical-align: middle;
  }

  & ul li {
    background: url("/icons/dot-arrow.svg") no-repeat left 1px;
    padding-left: 2em;
    margin-top: 1em;
    margin-left: -8px;
  }

  @media screen and (max-width: 1440px) {
    
  }

  @media screen and (max-width: 1000px) {
    font-size: 20px;
    line-height: 32px;
    & ul li {
      padding: 0 1em 0 2.5em;
      background-size: 30px 30px;
    }
  }

  @media screen and (max-width: 820px) {
    margin-bottom: 10px;
    
  }

  @media screen and (max-width: 670px) {
    font-size: 15px;
    line-height: 23px;
    & ul li {
      background-size: 25px 25px;
    }
  }

  @media screen and (max-width: 600px) {
    
  }
`

const PlanWrapper = styled.div`
  padding-bottom: 200px;
  
  & hr {
    border-top: 1.2px solid ${props => props.theme.colors.tertiary};
  }

  & .button-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & .button-content {
    margin-top: 50px;
    width: fit-content;
  }

  @media screen and (max-width: 1440px) {
    
  }

  @media screen and (max-width: 1000px) {
    padding-bottom: 150px;
  }

  @media screen and (max-width: 600px) {
    padding-bottom: 100px;
  }
  @media screen and (max-width: 400px) {
    padding-bottom: 80px;
  }
`

const DigitalPlans = props => {
  return (
    <Wrapper>
      {props.plans && props.plans.map((plan, index) => 
        <PlanWrapper
          key={`digital-plan-${index}`}
        >
          <Title>
            {plan.title}
          </Title>
          <hr/>

          {plan.content &&
            <Text 
              dangerouslySetInnerHTML={{
                __html: plan.content.childMarkdownRemark.html
              }}
            />
          }
          <div className="button-list">  
            <div className="button-content">
              <Button 
                className="rounded tertiary large"
                padding={props.paddingButton || "25px 40px"} 
                borderRadius="6.28832px"
                disabled>
                {plan.price}
              </Button>
            </div>
            <div className="button-content">
              <a 
                href={plan.buttonAction || "https://calendly.com/embello/brand-consultation?back=1&month=2020-10"} 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <Button 
                  className="medium"
                  padding={props.paddingButton || "15px 40px"} 
                  borderRadius="6.28832px">
                  {plan.buttonText || 'GET STARTED'}
                </Button>
              </a>
            </div>
          </div>

        </PlanWrapper>
      )}
    </Wrapper>
  )
}

export default DigitalPlans
