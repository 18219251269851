import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'

const Wrapper = styled.section`
  position: relative;
  /* // margin-top: 90px; */
  min-height: 200px;
  margin-bottom: 1em;
  padding: ${props => (props.padding ? props.padding : "90px 0")};
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
  }
`

const Title = styled.h1`
  width: 100%;

  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 45px;
  /* or 173% */

  text-align: center;
  letter-spacing: 0.13em;

  color: ${props => props.theme.colors.black};

  @media screen and (max-width: 1440px) {
    font-size: 26px;
  }

  @media screen and (max-width: 1000px) {
    letter-spacing: 4px;
    font-size: 22px;
  }

  @media screen and (max-width: 820px) {
    letter-spacing: 3px;
    font-size: 20px;
  }

  @media screen and (max-width: 670px) {
    font-size: 15px;
  }

  @media screen and (max-width: 600px) {
    letter-spacing: 3px;
    font-size: 12px;
  }
`

const Text = styled.div`
  width: 100%;

  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  /* or 141% */

  text-align: center;
  letter-spacing: 0.14em;

  color: ${props => props.theme.colors.tertiary};

  @media screen and (max-width: 1440px) {
    
  }

  @media screen and (max-width: 1000px) {
    font-size: 20px;
    line-height: 32px;
  }

  @media screen and (max-width: 820px) {
    
  }

  @media screen and (max-width: 670px) {
    font-size: 15px;
    line-height: 23px;
  }

  @media screen and (max-width: 600px) {
    
  }
`

const PlanWrapper = styled.div`
  padding: 25px;
  width: ${props => (props.width ? props.width : "33%")};

  @media screen and (max-width: 1100px) {
    width: 38%;
  }

  @media screen and (max-width: 820px) {
    width: 45%;
  }

  @media screen and (max-width: 600px) {
    width: 60%;
  }
  @media screen and (max-width: 500px) {
    width: 90%;
  }
`

const PlanPriceCard = styled.div`
  text-align: center;
  border: 1px solid #B56522;
  border-radius: 10px;
  padding: 57px 22px; 
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`


const PlanPriceImage = styled(Img)`
  margin: 0 10px;
  height: auto;
  width: 100%;
  max-width: 80px;
`

const PlanImages = styled.div`
  width: 100%;
  margin: ${props => (props.margin ? props.margin : "20px 0")};
  display: flex;
  justify-content: center;
`

const PlanPriceList = props => {
  return (
    <Wrapper padding={props.contentPadding}>
      {props.plans && props.plans.map((plan, index) => 
        <PlanWrapper
          key={`digital-plan-${index}`}
          width={props.width}
        >
          <PlanPriceCard>
            <Title>
              {plan.title}
            </Title>
            <PlanImages margin={props.marginImages}>
              {plan.images && plan.images.map((image, index) => 
                <PlanPriceImage
                  key={`price-image-${index}`}
                  fluid={image.fluid}
                />
              )}
            </PlanImages>
            <Text>
              {plan.price}
            </Text>
          </PlanPriceCard>
        </PlanWrapper>
      )}
    </Wrapper>
  )
}

export default PlanPriceList
