import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'

const Wrapper = styled.section`
  position: relative;
  /* // margin-top: 90px; */
  min-height: 300px;
  margin-bottom: 1em;
  padding: 109px 0 0;
`
const BgImg = styled(Img)``

const Title = styled.h1`
  width: 100%;

  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  font-size: 48px;
  letter-spacing: 5px;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 35px;

  @media screen and (max-width: 1440px) {
    font-size: 38px;
  }

  @media screen and (max-width: 1000px) {
    letter-spacing: 4px;
    font-size: 30px;
  }

  @media screen and (max-width: 820px) {
    letter-spacing: 3px;
    margin-bottom: 25px;
    font-size: 20px;
  }

  @media screen and (max-width: 670px) {
    font-size: 15px;
  }

  @media screen and (max-width: 600px) {
    letter-spacing: 3px;
    margin-bottom: 15px;
    font-size: 12px;
  }
`

const SlideWrapper = styled.div`
  padding: 0 0 1em;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  min-height: 200px;
  max-height: 100vh;
`

const HeroWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: ${props => props.background || 'transparent'};

  & .hero-content {
    display: flex;
    padding: 0 0 1em;
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    max-width: ${props => props.theme.sizes.maxNavWidth};
    left: 50%;
    transform: translate(-50%, 0%);

    & .hero-content-left{
      width: ${props => props.width || '50'}%;
      align-self: center;
      padding-left: 50px;

      & p, & h1  {
        text-align: left;
      }
    }

    & .hero-content-right{
      width: ${props => props.width || '50'}%;
      align-self: center;
      padding-right: 50px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      & p, & h1 {
        text-align: right;
      }

      & .button-content {
        text-align: right;
      }
    }

    & .button-content {
      & a {
        text-decoration: none;
      }
    }
  }

  & .hero-content.hero-right {
    justify-content: flex-end;
  }

  @media screen and (max-width: 1440px) {
    & .hero-content {
      & .hero-content-left{
        padding-left: 50px;
      }
      & .hero-content-right{
        padding-right: 50px;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    &.hero-content {
      & .hero-content-left{
        padding-left: 35px;
      }
      & .hero-content-right{
        padding-right: 35px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    &.hero-content {
      & .hero-content-left{
        padding-left: 25px;
      }
      & .hero-content-right{
        padding-right: 25px;
      }
    }
  }
  @media screen and (max-width: 400px) {
    & .hero-content {
      & .hero-content-left{
        padding-left: 15px;
      }
      & .hero-content-right{
        padding-right: 15px;
      }
    }
  }
`

const HeroBasic = ({ image, contentPosition, title, heroBackground, contentWidth, titleSize }) => {

  let heroWrapperClass = 'hero-content';
  
  if (contentPosition) heroWrapperClass += ` hero-${contentPosition}`;
  else heroWrapperClass += ' hero-right';

  return (
    <Wrapper>
      <SlideWrapper>
        <BgImg
          fluid={ image.fluid }
          alt={ image.title } 
        />
        <HeroWrapper width={ contentWidth || "50" } background={heroBackground || 'transparent'}>
          <div className={heroWrapperClass}>
            <div className={contentPosition ? `hero-content-${contentPosition}` : 'hero-content-right'}>
              <Title>
                {title}
              </Title>
            </div>
          </div>
        </HeroWrapper>
      </SlideWrapper>
    </Wrapper>
  )
}

export default HeroBasic
