import React from 'react'
import styled from 'styled-components'
import Button from './Button';
import Img from 'gatsby-image'
import { Link } from 'gatsby'

const Col = styled.div`
  &.col-medium {
    width: ${props => (props.sameCol ? "50%" : "60%")};
    padding: ${props => (props.sameCol ? "0 1em 0 2em" : props.inverted ? "0 3em 0 1em" : "0 2em")};
  }
  &.col-small {
    width: ${props => (props.sameCol ? "50%" : "40%")};
    padding: ${props => (props.sameCol ? "0 3em 0 1em" : props.inverted ? "0 1em 0 3em" : "0 2em")};
  }

  &.col-small {
    h3 {
      font-family: Raleway;
      font-style: normal;
      font-weight: normal;
      font-size: 38px;
      line-height: 39px;
      letter-spacing: 0.0727273px;
      color: ${props => props.theme.colors.tertiary};
    }

    p {
      font-family: Raleway;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0.0727273px;
      margin: 2em 0;
    }

    &.button-content {
      width: fit-content;

      a {
        text-decoration: none;
      }
    }
  }

  &.col-medium {
    h3 {
      font-family: Raleway;
      font-style: normal;
      font-weight: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 50px;
      letter-spacing: 0.2em;
      color: ${props => (props.tertiary ? props.theme.colors.tertiary : props.theme.colors.black)};
    }

    p {
      font-family: Raleway;
      font-style: normal;
      font-weight: normal;
      font-size: 26px;
      line-height: 50px;
      /* or 192% */

      letter-spacing: 0.14em;
      margin: 1em 0;
    }

    &.button-content {
      width: fit-content;

      a {
        text-decoration: none;
      }
    }
  }

  @media screen and (max-width: 1100px) {
    &.col-medium {
      h3 {
        font-size: 30px;
        line-height: 40px;
        letter-spacing: 0.0727273px;
      }
      p {
        font-size: 20px;
        line-height: 20px;
      }
    }
  }

  @media screen and (max-width: 800px) {
    &.col-medium {
      width: 100%;
      padding: 0 0.5em;
    }
    &.col-small {
      margin: 3em 0 1.5em 0;
      padding: 0 0.5em;
      width: 100%;
    }

    &.col-small {
      h3 {
        font-size: 30px;
        line-height: 32px;
        letter-spacing: 0.0727273px;
      }
      p {
        font-size: 16px;
        line-height: 20px;
      }
    }

    &.col-medium {
      h3 {
        font-size: 25px;
        line-height: 27px;
        letter-spacing: 0.0727273px;
      }
      p {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
`

const Row = styled.section`
  display: flex;
  width: 100%;
  max-width: ${props => (props.maxWidth ? props.maxWidth : props.theme.sizes.maxWidthCentered)};
  align-items: center;
  margin: 1em 0 5em 0;

  &.row-right {
    flex-direction: row-reverse;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    &.row-right {
      flex-direction: column;
    }
  }

  @media screen and (min-width: 600px) {
    padding: 0 3em 0 0;
  }

  @media screen and (min-width: 700px) {
    padding: 0;
  }
`

const BgImg = styled(Img)`
  height: auto;
  width: 100%;
`

const FeatureItem = props => {
  return (
    <Row className={`row-${props.contentPosition}`} maxWidth={props.maxWidth}>
      <Col className={props.invertedSizes ? "col-small" : "col-medium"} inverted={props.invertedSizes} sameCol={props.sameCols}>
        <BgImg fluid={props.image.fluid}/>
      </Col>
      <Col 
        className={props.invertedSizes ? "col-medium" : "col-small"} 
        inverted={props.invertedSizes} 
        sameCol={props.sameCols} 
        tertiary={props.tertiary}
      >
        <h3>{props.title || ''}</h3>
        <p 
          dangerouslySetInnerHTML={{
          __html: props.description || ''
          }}
        />
        {props.includeButton &&
          <div className="button-content">
            <Link to='/signup/bform'>
              <Button 
                padding="18.5px 44px" 
                borderRadius="6.28832px"
                className="rounded tertiary medium">
                GET A QUOTE
              </Button>
            </Link>
          </div>
        }
      </Col>
    </Row>
  )
}

export default FeatureItem
