import React from 'react'
import styled from 'styled-components'
import Button from './Button'
import Img from 'gatsby-image'

const Square = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  flex-direction: ${props => (props.inverted ? "row-reverse" : "row")};

  & .col-small {
    width: ${props => (props.inverted ? "40%" : "23%")};
    padding: ${props => (props.inverted ? "0 5em 0 1em" : "0 1em 0")};
  }

  & .col-medium {
    width: ${props => (props.inverted ? "60%" : "77%")};
    padding: ${props => (props.inverted ? "0 1em 0 0" : "0 0 0 1em")};
  }

  @media screen and (max-width: 1200px) {
    & .col-small {
      width: 30%;
    }

    & .col-medium {
      width: 70%;
    }
  }

  @media screen and (max-width: 1000px) {
    & .col-small {
      width: 33%;
      padding: ${props => (props.inverted ? "0 1em 0 1em" : "0 1em 0")};
    }

    & .col-medium {
      width: 67%;
      padding: ${props => (props.inverted ? "0 1em 0 2em" : "0 0 0 1em")};
    }
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    & .col-small {
      width: 50%;
      margin-bottom: 30px; 
    }

    & .col-medium {
      width: 100%;
    }
  }
`

const Wrapper = styled.section`
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;

  margin-top: ${props => props.noMargin ? '0' : '6em'};
  margin-bottom: ${props => props.noMargin ? '0' : '8em'};


  background: ${props => props.theme.colors.green};

  @media screen and (max-width: 600px) {
    margin-top: 1em;
    /* margin-bottom: 6em; */
  }
`

const Title = styled.h1`
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 56px;
  /* or 165% */
  letter-spacing: 0.2em;
  color: ${props => props.theme.colors.tertiary};
  align-self: center;

  @media screen and (max-width: 1200px) {
    font-size: 30px;
    line-height: 125%;
  }

  @media screen and (max-width: 1000px) {
    font-size: 26px;
    line-height: 125%;
  }

  @media screen and (max-width: 600px) {
    font-size: 22px;
    line-height: 125%;
  }
`

const Description = styled.h1`
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 27px;
  line-height: 33px;
  color: ${props => props.theme.colors.white};
  margin-top: 0.5em;
  margin-bottom: 1em;
  align-self: center;
  letter-spacing: 0.2em;

  @media screen and (max-width: 1200px) {
    font-size: 22px;
    line-height: 125%;
  }

  @media screen and (max-width: 1000px) {
    font-size: 20px;
    line-height: 125%;
  }

  @media screen and (max-width: 600px) {
    font-size: 18px;
    line-height: 125%;
  }
`

const Wrapper2 = styled.section`
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxNavWidth};
  padding: ${props => (props.padding ? props.padding : "3em 0em 3em 6em")};

  .button-content {
    width: fit-content;

    & a {
      text-decoration: none;
    }
  }

  @media screen and (max-width: 1700px) {
    padding: ${props => (props.padding ? props.padding : "3em 0em 3em 6em")};
  }

  @media screen and (max-width: 1200px) {
    padding: 3em 1em 3em 6em;
  }

  @media screen and (max-width: 900px) {
    padding: 2.5em 2em 2.5em 4em;
  }

  @media screen and (max-width: 700px) {
    padding: 2.5em 1em 2.5em 1em;
  }

  @media screen and (max-width: 500px) {
    padding: 2em 0.5em 2em 0.5em;
  }
  
`

const ColImage = styled(Img)`
  height: auto;
  width: 100%;
`

const GetQuoteGreenSection = props => {
  return (
    <Wrapper noMargin={props.noMargin}>
      <Wrapper2 padding={props.padding}>
        <Square inverted={props.inverted}>
          <div className="col-small">
            <ColImage 
              fluid={props.image.fluid}
            />
          </div>
          <div className="col-medium">
            <Title>{props.title}</Title>
            {props.description &&
              <Description>
                {props.description}
              </Description>
            }
            <div className="button-content">
              {props.linkTo &&
                <a 
                  href={props.linkTo} 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  <Button 
                    className="medium" 
                    padding={props.paddingButton || "15px 40px"} 
                    borderRadius="6.28832px"
                    onClick={props.onClick}>
                    {props.linkTitle || 'CONTACT US'}
                  </Button>
                </a>
              }
            </div>
          </div>

        </Square>
      </Wrapper2>
    </Wrapper>
  )
}

export default GetQuoteGreenSection
