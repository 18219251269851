import React from 'react'
import Layout from '../components/Layout'
import HeroBasic from '../components/HeroBasic'
import FeatureItem from '../components/FeatureItem'
import styled from 'styled-components'
import Theme from '../styles/theme' 
import DigitalPlans from '../components/DigitalPlans'
import GetQuoteGreenSection from '../components/GetQuoteGreenSection'
import DigitalPLanDetails from '../components/DigitalPlanDetails'
import PlanPriceList from '../components/PlanPriceList'

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: ${props => (props.marginTop ? props.marginTop : '9em')};
  align-items: center;

  .button-content {
    width: fit-content;

    & a {
      text-decoration: none;
    }
  }

  @media screen and (max-width: 700px) {
    margin-top: 6em;
    padding: 0 1.5em;
  }
`

const DigitalServicesPage = ({ data, pageContext }) => {

  const contentPage = data.contentfulDigitalServicesPage
  const secondFeature = {
    image: contentPage.secondFeatureImage,
    title: contentPage.secondFeatureTitle,
    description: contentPage.secondFeatureText.childMarkdownRemark.html
  }

  let layoutRef = React.createRef();

  return (
    <Layout ref={layoutRef} whiteNav>
      <HeroBasic 
        image={contentPage.heroImage}
        title={contentPage.heroTitle}
        contentPosition="right"
      />
      <Wrapper>
        <FeatureItem 
          image={contentPage.firstFeatureImage}
          title={contentPage.firstFeatureTitle}
          contentPosition="left"
          maxWidth={Theme.sizes.maxNavWidth}
          invertedSizes
          description={contentPage.firstFeatureText.childMarkdownRemark.html}
        />

        <DigitalPlans
          plans={contentPage.digitalPlans}
        />
      </Wrapper>

      <GetQuoteGreenSection 
        linkTo={contentPage.firstBannerAction}
        noMargin
        title={contentPage.firstBannerTitle}
        description={contentPage.firstBannerDescription}
        image={contentPage.firstBannerImage}
      />

      <PlanPriceList 
        plans={contentPage.featurePrices}
      />

      <DigitalPLanDetails 
        content={contentPage.content}
        padding={"90px 0 60px"}
        feature={secondFeature}
        plataformPrices={contentPage.plataformPrices}
      />

      <GetQuoteGreenSection 
        linkTo={contentPage.secondBannerAction}
        noMargin
        title={contentPage.secondBannerTitle}
        description={contentPage.secondBannerDescription}
        image={contentPage.secondBannerImage}
        inverted
        padding={"5em 0em 5em 6em"}
        linkTitle={"GET STARTED"}
      />
    </Layout>
  )
  
}

export const query = graphql`
  query {
    contentfulDigitalServicesPage(version: { eq: "current" }) {
      heroImage {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      heroTitle
      firstFeatureTitle
      firstFeatureImage {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      firstFeatureText {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 80)
        }
      }
      digitalPlans {
        title
        content {
          childMarkdownRemark {
            html
          }
        }
        price
        buttonAction
      }
      firstBannerTitle
      firstBannerImage {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      firstBannerDescription
      firstBannerAction
      content {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 80)
        }
      }
      secondFeatureTitle
      secondFeatureImage {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      secondFeatureText {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 80)
        }
      }
      featurePrices {
        title
        images {
          title
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        price
      }
      plataformPrices {
        title
        images {
          title
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        price
      }
      secondBannerTitle
      secondBannerImage {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      secondBannerDescription
      secondBannerAction
    }
  }
`

export default DigitalServicesPage
